<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <name-text />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <surname-text />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <email-text />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <phone-text />
      </b-col>
      <b-col>
        <password />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import NameText from '@/views/Client/Account/elements/NameText.vue'
import SurnameText from '@/views/Client/Account/elements/SurnameText.vue'
import EmailText from '@/views/Client/Account/elements/EmailText.vue'
import PhoneText from '@/views/Client/Account/elements/PhoneText.vue'
import Password from '@/views/Client/Account/elements/Password.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    NameText,
    SurnameText,
    EmailText,
    PhoneText,
    Password,
  },
}
</script>
