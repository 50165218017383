<template>
  <div>
    <b-form-group
      v-if="dataItem.id"
      :label="$t('Yeni Parola')"
      label-for="autopassword"
    >
      <b-form-checkbox
        id="autopassword"
        v-model="newPassword"
        switch
      />
    </b-form-group>
    <b-form-group
      v-if="newPassword"
      :label="$t('Parola')"
      label-for="password"
    >
      <validation-provider
        #default="{ errors }"
        :name="$t('Parola')"
        rules="required"
      >
        <b-input-group class="input-group-merge">
          <b-form-input
            id="password"
            v-model="dataItem.password"
            :type="passwordFieldType"
            :placeholder="$t('Parola')"
          />
          <b-input-group-append is-text>
            <feather-icon
              :icon="passwordToggleIcon"
              class="cursor-pointer"
              @click="togglePasswordVisibility"
            />
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: 'Password',
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      autoPassword: true,
      newPassword: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    dataItem() {
      return this.$store.getters['account/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
